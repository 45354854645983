import ExtendedAxios from '../helpers/axios';

import { FFM_CONSOLE_API_URL } from '../config/env';
import AwsService from './aws';
import { BUCKET_TYPES } from '../config';
import isURI from "validate.io-uri";
import {addProtocolToUrl} from "../helpers/url";
import {url} from "../validators";

const awsService = new AwsService;
const axios = new ExtendedAxios({baseURL: FFM_CONSOLE_API_URL});

export default class MiscService {
  constructor( store ) {
    if ( store ) {
      axios.store = store;
    }
  }

  async smartlinkResolver( params ) {
    const originalSearchTerm = params.q;
    params.q = url(params.q) ? addProtocolToUrl(params.q) : params.q;
    const res = await axios.get( '/smartlink-resolver', params );
    return {q: originalSearchTerm, items: res, error: res.error};
  }

  async spotifyArtistSearch( params ) {
    const res = await axios.get( '/spotify-artist-search', params )
    res.q = params.q;
    return res;
  }

  locations() {
    return axios.get( '/locations' )
  }

  accountTypes() {
    return [{
      code: 'label',
      name: 'Label'
    },{
      code: 'management',
      name: 'Management'
    },{
      code: 'distribution',
      name: 'Distribution'
    },{
      code: 'marketing_agency',
      name: 'Marketing Agency'
    },{
      code: 'other',
      name: 'Other'
    }]
  }

  ipLocation() {
    return axios.get( '/guess-client-location');
  }

  async artistPictureUpload( file, onProgress ) {
    // GTM.track('File Upload', 'Started', {
    //   filename: file.name,
    //   type: file.type,
    //   size: file.size,
    // });
    //  Get signature
    const awsSignatureResponse = await awsService.getSignatureForFile(file, BUCKET_TYPES.IMAGES_BUCKET);
    //  Destruct the response for convenience
    const {
      signature,
      policy,
      uploadedFileUrl,
      uploadUrl,
      fileName
    } = awsSignatureResponse.data;
    //  Await for promise which will either:
    //    1. Resolve and set this `response` variable
    //    2. Reject and come right into `catch` clause elsewhere
    const response = await awsService.uploadFile({
      uploadUrl,
      signature,
      policy,
      file,
      onProgress
    });

    // GTM.track('File Upload', 'Uploaded', { filename: file.name });

    return { uploadedFileUrl, response };
  }

  editArtist( form ) {
    return axios.post( '/artist', form );
  }

  completesignup( params ) {
    return axios.post( '/completesignup', params );
  }
}
