import _ from "lodash";
import { ARTIST_TYPES, DEFAULT_LOCATION, DEFAULT_CURRENCY, EUR_CURRENCY, GBP_CURRENCY } from "@featurefm/common/config";
import MiscService from "@featurefm/common/services/misc";
import qs from "qs";

const miscService = new MiscService()

export const ARTIST_FIELDS = {
    about: "",
    genres: [],
    similarArtists: [],
    type: "artist",
    name: "",
    website: "",
    currency: "USD",
    location: "",
    facebook: "",
    twitter: "",
    youtube: "",
    spotifyArtistPage: "",
    soundCloudArtistPage: "",
    tiktokArtistPage: "",
    instagram: "",
    profileImage: ""
};

export function processResolverResponse(data) {
    const items = (data || {}).items || [];

    const keyed = _.keyBy(items, item => {
        return item.id;
    });

    const name = _.find(items, item => {
        return typeof item.title !== "undefined" && item.title.length > 0;
    }).title;

    const imageUrl = (_.find(items, item => {
        return typeof item.imageUrl !== "undefined" && item.imageUrl != null && item.imageUrl.length > 0;
    }) || {}).imageUrl;

    return {
        q: data.q,
        items: [{
            name: name || "",
            type: ARTIST_TYPES.ARTIST,
            profileImage: imageUrl || "",
            spotifyArtistPage: keyed.spotify.webUrl || "",
            tiktokArtistPage: ""
        }]
    };
}

export function processSpotifyArtistsResponse(data) {
    const getSingleArtist = (item) => {
        const name = (typeof item.name !== "undefined" && item.name && item.name.length > 0) ? item.name : '';
        const imageUrl = (typeof item.images !== "undefined" && item.images && item.images.length && item.images[0].url && item.images[0].url.length > 0) ? item.images[0].url : '';
        const spotifyArtistPage = (typeof item.id !== "undefined" && item.id && item.id.length > 0) ? `https://open.spotify.com/artist/${item.id}` : '';

        return {
            key: `spotify_${item.id}`,
            name: name,
            type: ARTIST_TYPES.ARTIST,
            profileImage: imageUrl,
            spotifyArtistPage: spotifyArtistPage,
            tiktokArtistPage: ""
        };
    }

    const ret = {q: data.q, items: []}

    if (data && data.artists && data.artists.items && data.artists.items.length) {
        ret.items = data.artists.items.map(item => getSingleArtist(item));
    }

    return ret;
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getCurrency( location ) {

    const code = location['country_code']

    if ( EUR_CURRENCY.includes( code ) ) {
        return 'EUR';
    } else if ( GBP_CURRENCY.includes( code ) ) {
        return 'GBP';
    } else {
        return DEFAULT_CURRENCY;
    }
}

export async function getClientCountryCurrency() {
    try {
        const response = await miscService.ipLocation();

        if (response.error) {
            return {
                location: DEFAULT_LOCATION,
                currency: DEFAULT_CURRENCY
            }
        }

        return {
            location: (response && response['country_code']) ? response['country_code'].toLowerCase() : DEFAULT_LOCATION,
            currency: getCurrency(response)
        }
    } catch {
        return {
            location: DEFAULT_LOCATION,
            currency: DEFAULT_CURRENCY
        }
    }
}

export function getCurrencyFromQueryString(rawQueryString) {
    let qsCurrency = (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).currency || '');
    if (qsCurrency === '$') {
        qsCurrency = 'USD'
    }
    if (qsCurrency === '€') {
        qsCurrency = 'EUR'
    }
    if (qsCurrency === '£') {
        qsCurrency = 'GBP'
    }

    if (qsCurrency && ['EUR', 'GBP', DEFAULT_CURRENCY].indexOf(qsCurrency.toUpperCase()) !== -1) {
        return qsCurrency.toUpperCase();
    }

    return null;
}
