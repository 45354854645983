import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faSpotify,
  faSoundcloud,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import FFMIcon from "@featurefm/common/components/FFMIcon";
import ImageDropzone from "@featurefm/common/components/ImageDropzone";
import FormDropdown, { DropdownMenuGlobalStyles } from "@featurefm/common/components/FormDropdown";
import FormInput, {
  FormGroup,
  FormLabel
} from "@featurefm/common/components/FormInput";
import MiscService from "@featurefm/common/services/misc";

import { Title, Form, menuStyleOverride, Error } from "../styled";
import LocationLabel from "./LocationLabel";
import AccountTypeLabel from "./AccountTypeLabel";
import { BUDGET_PACKAGES_REFERRER_IDS } from "@featurefm/common/config/env";
import { CURRENCIES } from "@featurefm/common/config";

const miscService = new MiscService();

class ManualFormLabel extends Component {
  state = {
    imageUploadLoading: false,
    imageError: null,
    locationsLoading: true,
    locations: null,
  };

  onDrop = async files => {
    this.setState({imageUploadLoading: true, imageError: null})
    try {
      // eslint-disable-next-line no-unused-vars
      const { uploadedFileUrl, response } = await miscService.artistPictureUpload(files[0]);
      this.props.setPicture( uploadedFileUrl );
    } catch (error) {
      this.setState( { imageError: error } );
    } finally {
      this.setState({imageUploadLoading: false})
    }
  };

  getImageErrorMessage() {
    const { intl } = this.props;
    const { imageError } = this.state;

    if ( imageError.target && imageError.target.toString() === '[object XMLHttpRequest]' ) {
      return intl.messages.ERROR_NETWORK;
    } else if ( imageError.message ) {
      return imageError.message;
    }

    return intl.messages.ERROR_UNKNOWN;
  }

  async getLocations() {
    const { data: locations } = await miscService.locations();

    this.setState({ locationsLoading: false, locations });
  }

  getAccountTypes() {
    const accountTypes = miscService.accountTypes();

    this.setState({ accountTypesLoading: false, accountTypes });
  }

  getCurrencies() {
    const { user } = this.props;
    // currently GBP is disabled for TuneCore users (identified by affiliate ids), due to budget packages minimum budget issue
    const affiliationReferrer = user && user.referrer ? user.referrer.id : null;
    const foundAffiliationForPackages = BUDGET_PACKAGES_REFERRER_IDS.includes( affiliationReferrer );

    const currencies = _.filter( CURRENCIES, foundAffiliationForPackages ? c => (c.validInSignup && c.validForBudgetPackages) : 'validInSignup');

    this.setState({ currencies });
  }

  componentDidMount() {
    this.getLocations();
    this.getAccountTypes();
    this.getCurrencies();
  }

  render() {
    const { formChange, clearPicture, accountTypeChange, locationChange, currencyChange, error, user, intl, review } = this.props;
    const { accountTypes, locations, currencies, imageUploadLoading, imageError } = this.state;

    const {
      name,
      profileImage,
      currency,
      website,
      instagram,
      facebook,
      twitter,
      youtube,
      soundcloud,
      spotifyArtistPage,
      tiktokArtistPage,
      location,
      accountType,
      errors,
    } = this.props.form


    const locationOptions = locations && locations.map( loc => ({ value: loc.code, label: <LocationLabel location={loc} /> }) );
    const locationValue = locations && _.find( locations, loc => (loc.code === location) );
    const locationDropdownValue = locationValue && { value: locationValue.code, label: <LocationLabel location={locationValue} /> };

    const accountTypeOptions = accountTypes && accountTypes.map( actType => ({ value: actType.code, label: <AccountTypeLabel accountType={actType} /> }) );
    const accountTypeValue = accountTypes && _.find( accountTypes, actType => (actType.code === accountType) );
    const accountTypeDropdownValue = accountTypeValue && { value: accountTypeValue.code, label: <AccountTypeLabel accountType={accountTypeValue} /> };

    const currencyOptions = currencies && currencies.map( cur => ({ value: cur.code, label: cur.text }) );
    const currencyValue = currency && _.find( currencies, cur => (cur.code === currency) );
    const currencyDropdownValue = currencyValue && { value: currencyValue.code, label: currencyValue.text };

    return (
      <>
        <DropdownMenuGlobalStyles {...menuStyleOverride}/>

        { user &&
          <Title id="onboarding-manual-title">
            { intl.formatMessage( { id: 'ONBOARDING_LABEL_TITLE' }, {
              user: user.givenName || user.firstName || user.name,
              action: review ? 'complete' : 'create'
            } ) }
          </Title>
        }

        { error && <Error id="onboarding-error">{ error.message }</Error>}

        <Form id="onboarding-manual-container" manual>

          {/* Avatar */}
          <FormGroup>
            <FormLabel>
              { intl.messages.ACCOUNT_IMAGE_LABEL }
            </FormLabel>
            <ImageDropzone
              label={ intl.messages.AVATAR_DROPZONE_LABEL }
              guidelines={ intl.messages.AVATAR_DROPZONE_GUIDELINES }
              error={ imageError && this.getImageErrorMessage() }
              onDrop={this.onDrop}
              onClear={clearPicture}
              loading={imageUploadLoading}
              image={profileImage}
            />
          </FormGroup>

          {/* Account Type */}
          <FormGroup>
            <FormLabel required>
              { intl.messages.ACCOUNT_TYPE_LABEL }
            </FormLabel>
            <FormDropdown
              id="onboarding-account-type-dropdown"
              error={ errors.accountType }
              name="type"
              placeholder={ intl.messages.ACCOUNT_TYPE_PLACEHOLDER }
              value={accountTypeDropdownValue}
              onChange={accountTypeChange}
              options={accountTypeOptions}
              isLoading={!accountTypes}
              filterProperties={[ 'label.props.accountType.name', 'label.props.accountType.code' ]}
            />
          </FormGroup>

          {/* Location
          <FormGroup>
            <FormLabel required>
              { intl.messages.LOCATION_LABEL }
            </FormLabel>
            <FormDropdown
              id="onboarding-location-dropdown"
              error={ errors.location }
              name="location"
              placeholder={ intl.messages.LOCATION_PLACEHOLDER }
              value={locationDropdownValue}
              onChange={locationChange}
              options={locationOptions}
              isLoading={!locations}
              filterProperties={[ 'label.props.location.text', 'label.props.location.code' ]}
            />
          </FormGroup>
          */}

          {/* Currency */}
          <FormGroup>
            <FormLabel required>
              { intl.messages.CURRENCY_LABEL }
            </FormLabel>
            <FormDropdown
              id="onboarding-currency-dropdown"
              error={ errors.currency }
              name="currency"
              placeholder={ intl.messages.CURRENCY_PLACEHOLDER }
              value={currencyDropdownValue}
              onChange={currencyChange}
              options={currencyOptions}
              isLoading={!currencies}
              filterProperties={[ 'label', 'value' ]}
            />
          </FormGroup>

          {/* Website */}
          <FormGroup>
            <FormLabel>
              { intl.messages.WEBSITE_LABEL }
            </FormLabel>
            <FormInput
              error={website && website.length > 0 && errors.website}
              name="website"
              placeholder={ intl.messages.WEBSITE_PLACEHOLDER }
              value={website || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faGlobe} />}
            />
          </FormGroup>

          {/* Spotify */}
          <FormGroup>
            <FormLabel>
              { intl.messages.SPOTIFY_LABEL }
            </FormLabel>
            <FormInput
              error={ spotifyArtistPage && spotifyArtistPage.length > 0 && errors.spotifyArtistPage}
              name="spotifyArtistPage"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={spotifyArtistPage || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faSpotify} />}
            />
          </FormGroup>

          {/* Instagram */}
          <FormGroup>
            <FormLabel>
              { intl.messages.INSTAGRAM_LABEL }
            </FormLabel>
            <FormInput
              error={ instagram && instagram.length > 0 && errors.instagram}
              name="instagram"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={instagram || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faInstagram} />}
            />
          </FormGroup>

          {/* YouTube */}
          <FormGroup>
            <FormLabel>
              { intl.messages.YOUTUBE_LABEL }
            </FormLabel>
            <FormInput
              error={ youtube && youtube.length > 0 && errors.youtube}
              name="youtube"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={youtube || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faYoutube} />}
            />
          </FormGroup>

          {/* TikTok */}
          <FormGroup>
            <FormLabel>
              { intl.messages.TIKTOK_LABEL }
            </FormLabel>
            <FormInput
              error={ tiktokArtistPage && tiktokArtistPage.length > 0 && errors.tiktokArtistPage}
              name="tiktokArtistPage"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={tiktokArtistPage || ''}
              onChange={formChange}
              prefix={<FFMIcon icon="tiktok" />}
            />
          </FormGroup>

          {/* Facebook */}
          <FormGroup>
            <FormLabel>
              { intl.messages.FACEBOOK_LABEL }
            </FormLabel>
            <FormInput
              error={ facebook && facebook.length > 0 && errors.facebook}
              name="facebook"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={facebook || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faFacebook} />}
            />
          </FormGroup>

          {/* Soundcloud */}
          <FormGroup>
            <FormLabel>
              { intl.messages.SOUNDCLOUD_LABEL }
            </FormLabel>
            <FormInput
              error={ soundcloud && soundcloud.length > 0 && errors.soundcloud}
              name="soundcloud"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={soundcloud || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faSoundcloud} />}
            />
          </FormGroup>

          {/* Twitter */}
          <FormGroup>
            <FormLabel>
              { intl.messages.TWITTER_LABEL }
            </FormLabel>
            <FormInput
              error={ twitter && twitter.length > 0 && errors.twitter}
              name="twitter"
              placeholder={ intl.messages.URL_PLACEHOLDER }
              value={twitter || ''}
              onChange={formChange}
              prefix={<FontAwesomeIcon icon={faTwitter} />}
            />
          </FormGroup>

          {/* <Button variant="primary" type="button" disabled={ link.length === 0 || linkError } loading={ loading } onClick={ this.submit }>
            { !loading && "Search" }
            { loading && <MetroSpinner size="20" /> }
          </Button> */}
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManualFormLabel));
